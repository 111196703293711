import React from 'react';
import T from '../T';
import Img from "gatsby-image"
import FollowFooter from '../shared/FollowFooter';
function RecipesContent({ content, ingredients, mainImage, measures }) {
  return (
    <div className="tile is-ancestor">
      <div className="tile is-4 is-vertical is-parent">
        <div className="tile is-child box">
          <div className="recipe__image-container">
            <div className="">
              <Img fluid={mainImage.fluid} imgStyle={{
                objectFit: "contain",
              }} />
            </div>
          </div>

        </div>

        {

          measures.map((item, i) => <T key={i} item={item} />)
        }

      </div>

      <div className="tile is-parent">
        <div className="tile is-vertical instructions">
          {content.map((item, index) => {
            if (item.json.content[0].content[0].value.length) {
              return (
                <T key={index} item={item} padding={true} />
              )
            } else {
              return false
            }
          })}
          <FollowFooter
            link="https://www.instagram.com/foodslut"
            copy="Follow us on instagram"
            buttonCopy="FOLLOW"

          />
        </div>

      </div>

    </div>
  );
}

export default RecipesContent;



