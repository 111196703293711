import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import LayoutContent from '../shared/layoutContent';

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ title, backgroundImage, feeds, slutRating, cookingTime }) => (
    <StaticQuery
        query={graphql`
        query($slug: String){
            contentfulRecipes(slug: {eq: $slug}){
              id
              backgroundImage {
                fluid(maxWidth: 500, maxHeight:600) {
                    src
                    ...GatsbyContentfulFluid_withWebp

                }
              }
            }
          }
    `}
        render={data => {
            return (
                <BackgroundImage
                    Tag="section"
                    fluid={backgroundImage}
                    className="hero is-primary is-medium"
                >
                    <div className="hero-body">
                        <LayoutContent>
                        <div className="container content-container">
                            <h1 className="title">{title}</h1>
                            <h2 className="subtitle">Feeds: {feeds}</h2>
                       
                        </div>
                        </LayoutContent>
                    </div>
                    
                </BackgroundImage>
            )
        }}
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
backgroundSize: 'contain',
backgroundRepeat: 'no-repeat',
backgroundPosition: 'right center'
`

export default StyledBackgroundSection

