import React from 'react';
import RecipeContent from '../components/Recipes/RecipesContent';
import Layout from '../components/shared/layout';
import LayoutContent from '../components/shared/layoutContent';
import Video from '../components/Video'
import BackgroundImage from '../components/shared/BackgroundImage'
import { graphql } from 'gatsby'
import SEO from '../components/seo';
import '../pages/index.css'

function Recipe({ data }) {
  const { tags, name,slug,  backgroundImage, childContentfulRecipesRecipeRichTextNode, video, ingredients, mainImage, slutRating, feeds, cookingTime, childContentfulRecipesIngredientsAndMeasuresRichTextNode } = data.contentfulRecipes
  const contentArray = [childContentfulRecipesRecipeRichTextNode]
    .filter(x => x)
  const measures = [childContentfulRecipesIngredientsAndMeasuresRichTextNode]
  return (
    <Layout>
      <SEO
        title={name}
        mainImage={`https:${mainImage.fluid.src}`}
        url={`/recipes${slug}`}
      />
      <div>
        <BackgroundImage
          title={name}
          backgroundImage={backgroundImage.fluid}
          cookingTime={cookingTime}
          slutRating={slutRating}
          feeds={feeds}

        />
        {video ?
          <div className="recipe-video-wrapper">
            <Video
              src={video}
            />
          </div> : null}



        <LayoutContent>
          <RecipeContent
            content={contentArray}
            tags={tags}
            ingredients={ingredients}
            mainImage={mainImage}
            measures={measures}

          />

        </LayoutContent>
      </div>

    </Layout>
  );
}

export default Recipe;

export const query = graphql`
query($slug: String){
  contentfulRecipes(slug: {eq: $slug}){
    name
    id
    slug
    tags
    ingredients
    slutRating
    feeds
    video
    cookingTime
    mainImage {
      fluid(maxWidth: 500, maxHeight:500){
        src
      ...GatsbyContentfulFluid
    }
  }
  backgroundImage {
    fluid(maxWidth: 500, maxHeight:600) {
        src
        ...GatsbyContentfulFluid_withWebp

    }
  }

  childContentfulRecipesRecipeRichTextNode {
    json
  }
  childContentfulRecipesIngredientsAndMeasuresRichTextNode {
    json
  }
    
  }
}`





