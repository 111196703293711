import React from 'react';

function Video({src, youTube}) {
    return (
        <div className="videoWrapper">
            {youTube ?
            <iframe title="Stay at th" width="560" height="315" src={src} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> :
            <iframe title="Stay at home Burger Battle" src={src}
                width="560" height="349" 
                allow="autoplay; fullscreen" allowFullScreen></iframe>
        }
            
        </div>
    );
}

export default Video;